import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled, { createGlobalStyle } from "styled-components"
import CssBaseline from '@material-ui/core/CssBaseline';
import { graphql } from 'gatsby';
import Nav from '../components/Nav'
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.white ? '#FFFFFF' : '#F0F0F0')};
  }
`
const Kehys = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 40px;
padding-right: 40px;
height: auto;
background-color: 'white';
`
const KehysM = styled.div`
padding-top: 40px;
padding-bottom: 40px;
padding-left: 20px;
padding-right: 20px;
height: auto;
background-color: 'white';
`
const SisempiKehys = styled.div`
font-size: 14px;
line-height: 20px;
height: auto;
`
const Otsikko = styled.h1`
padding: 0px;
margin: 0px;
font-weight: bold;
font-size: 16px;
`
const Desktop = styled.div`
width: 1260px;
background:white;
height: 100;
`
const Responsive = styled.div`
display:grid;
grid-template-columns: 1fr 1260px 1fr;
height: 100vh;
margin:0;
padding:0;
`
const BackGround = styled.div`
background-color: #F0F0F0;
z-index: -1000;
height: 100%;
`

const Esittely = ({ yhteystiedot }) => {
  const { nimi, osoite, puhelin, email } = yhteystiedot;
  return (
    <SisempiKehys>
      <Otsikko>Yhteystiedot</Otsikko>
      <Otsikko style={{ paddingTop: 14 }}>{nimi}</Otsikko>
      <p>{email}</p>
      <p>{puhelin}</p>
      <p>{osoite}</p>
    </SisempiKehys>
  )
}

const DesktopContact = ({ yhteystiedot }) => {
  return (
    <Fragment>
      <GlobalStyle white />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka - Koukkulavavaunut</title>
      </Helmet>
      <Responsive>
        <BackGround />
        <Desktop>
          <CssBaseline />
          <Nav />
          <StaticImage
            alt="teksti"
            src="../assets/metaka_etusivu.jpg"
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              maxHeight: 600,
              marginTop: '64px',
            }}
            layout="fullWidth"
            objectFit='cover'
          />
          <Kehys>
            <Esittely yhteystiedot={yhteystiedot} />
          </Kehys>
        </Desktop>
        <BackGround />
      </Responsive>
    </Fragment>
  )
}

const MobileContact = ({ yhteystiedot }) => {
  return (
    <Fragment>
      <GlobalStyle white />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Metaka - Koukkulavavaunut</title>
      </Helmet>
      <CssBaseline />
      <Nav />
      <StaticImage
        alt="teksti"
        src="../assets/metaka_etusivu.jpg"
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 600,
          marginTop: '64px'
        }}
        layout="fullWidth"
        objectFit='cover'
      />
      <Kehys>
        <Esittely yhteystiedot={yhteystiedot} />
      </Kehys>
    </Fragment>
  )
}

function ContactPage({ data }) {
  const breakpoints = useBreakpoint();
  const yhteystiedot = data.allSanityContact.nodes[0]
  return (
    <div>
      {breakpoints.md ? <MobileContact yhteystiedot={yhteystiedot} /> : <DesktopContact yhteystiedot={yhteystiedot} />}
    </div>
  )
}

export default ContactPage

export const query = graphql`
query Yhteystiedot {
  allSanityContact {
    nodes {
      nimi
      osoite
      puhelin
      email
    }
  }
}
`




